import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import strings from "../../strings";
import { observer } from "mobx-react";
import AllDoorsComponent from "../../components/AllDoorsComponent/AllDoorsComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import ShowTicketComponent from "../../components/ShowTicketComponent/ShowTicketComponent";
import { InfoDogContinue } from "../../components/ModalComponent/ModalComponent";
import HowItsEasyComponent from "../../components/HowItsEasyComponent/HowItsEasyComponent";
import AlertInfoComponent from "../../components/AlertInfoComponent/AlertInfoComponent";
import { timerEvent } from "../../utils/methods";

export default observer((props) => {
  const history = useHistory();
  const { lockerStore, doorsStore } = props;
  const { order } = lockerStore;
  const { openDoors } = doorsStore;
  const user = lockerStore.getUser();
  const totalDoors = order.containers.length;

  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [modalEnd, setModalEnd] = useState(false);

  const openDoor = async (e, container) => {
    await doorsStore.checkOpenDoor();
    if (openDoors != null) {
      await doorsStore.openDoorCommandRetrieveOrder(container);
      await lockerStore.deallocateContainer(container.id);
    }
    setInitialSetupDone(true);
  };

  const handleOrderDeallocation = async () => {
    lockerStore.deallocateOrder();
    await order.containers.forEach((door) => {
      openDoor(null, door);
    });
  }

  useEffect(() => {
    handleOrderDeallocation();
    return () => {
      doorsStore.cleaningIntervals();
    };
  }, []);

  if (order && initialSetupDone && openDoors?.length == 0 && !modalEnd) {
    setModalEnd(true);
  }

  return (
    <React.Fragment>
      {/** MODAL AREA*/}
      {
        <InfoDogContinue
          show={modalEnd}
          timerEvent={timerEvent(history)}
          text={strings.get("pedido_retirado_com_sucesso")}
          onClickContinue={() => {
            doorsStore.reset();
            history.replace("/")
          }}
        />
      }
      {/** MODAL AREA*/}

      <section className="global-structure section1">

        {/** CENTER AREA*/}
        <div
          className="d-flex flex-column justify-content-between align-items-center"
          style={{ height: "100vh" }}
        >
          <BlockComponent medium style={{ marginBottom: "3rem" }}>
            <SpacerComponent>
              <span className="text-featured">
                {strings.get("seja_bem_vindo")}
                {user ? user.name : ""}
              </span>
            </SpacerComponent>
            {order ? (
              <BlockComponent large>
                <SpacerComponent>
                  <ShowTicketComponent>
                    <p className="text-small order">
                      {strings.get("numero_pedido")}
                    </p>
                    <span className="text-small">{order.password}</span>
                  </ShowTicketComponent>
                </SpacerComponent>
              </BlockComponent>
            ) : null}

            <SpacerComponent>
              <div className="text-center" style={{ fontSize: "1.5rem" }}>
                Seu pedido está contido em{" "}
                <span className="text-featured">{totalDoors}</span> portas.{" "}
                <br></br>Restam{" "}
                <span className="text-featured">
                  {initialSetupDone && openDoors?.length}{" "}
                </span>
                portas para retirar produtos.
              </div>
            </SpacerComponent>

            <SpacerComponent>
              <div className="group_mosaic_locker">
                {initialSetupDone != null && (
                  <AllDoorsComponent
                    containers={order ? order.containers : []}
                    currentDoor={openDoors?.map((e) => e.toString())}
                  />
                )}
              </div>
            </SpacerComponent>
            <AlertInfoComponent show={true}>
              <div className="text-center">
                <span>
                  Após abrir, retire os produtos e feche a porta. Para abrir uma
                  nova porta, a porta anterior deve estar fechada.
                </span>
              </div>
            </AlertInfoComponent>
          </BlockComponent>
          {/** CENTER AREA*/}

          {/** BOTTOM AREA*/}
          <BottomComponent>
            <BlockComponent>
              <SpacerComponent>
                <HowItsEasyComponent />
              </SpacerComponent>
            </BlockComponent>
          </BottomComponent>
        </div>

        {/** BOTTOM AREA*/}
      </section>
    </React.Fragment>
  );
});
