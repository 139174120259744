import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import strings from "../../strings";
import { observer } from "mobx-react";
import { Spinner } from "react-bootstrap";
import TopComponent from "../../components/TopComponent/TopComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import TableReversaComponent from "../../components/TableReversaComponent/TableReversaComponent";
import BackIconComponent from "../../components/BackIconComponent/BackIconComponent";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

export default observer((props) => {
  const history = useHistory();

  const { lockerStore } = props;
  const { user } = lockerStore;

  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);

  const fetchReverses = async () =>
    await lockerStore.reversesOrder().then(() => setLoading(false));

  useEffect(() => {
    fetchReverses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickReverse = async (reverse) => {
    lockerStore.order = reverse;
    history.push("/reverseorder");
  };

  return (
    <React.Fragment>
      {/** MODAL AREA*/}
      <ModalComponent
        show={modalError}
        onClickBackground={() => {
          setModalError(false);
        }}
      >
        <div className="error-notification text-danger">
          Algo deu errado. Tente novamente.
        </div>

        <ButtonComponent
          state="btn btn-success"
          click={() => {
            setModalError(false);
          }}
        >
          {strings.get("continuar")}
        </ButtonComponent>
      </ModalComponent>
      {/** MODAL AREA*/}
      <section className="global-structure section1">
        {/** TOP AREA*/}
        <TopComponent suply>
          <TitleHeaderComponent
            back={<BackIconComponent onClick={history.goBack} />}
          >
            {strings.get("modulo_de_reversa")}
          </TitleHeaderComponent>
        </TopComponent>
        {/** TOP AREA*/}

        {/** CENTER AREA*/}
        <BlockComponent medium>
          <div>
            <TableReversaComponent
              itens={lockerStore.reverses}
              user={user && user.name}
              buttonHandler={onClickReverse}
            />

            {loading && <Spinner animation="border" />}
          </div>
        </BlockComponent>
        {/** CENTER AREA*/}
      </section>
    </React.Fragment>
  );
});
