import React, { useState, useEffect } from "react";
import DoorsOfOrderComponent from "../../components/DoorsOfOrderComponent/DoorsOfOrderComponent";
import { useHistory } from "react-router";
import strings from "../../strings";
import { observer } from "mobx-react";
import TopComponent from "../../components/TopComponent/TopComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import { InfoContinue } from "../../components/ModalComponent/ModalComponent";

import AlertInfoComponent from "../../components/AlertInfoComponent/AlertInfoComponent";
import { timerEvent } from "../../utils/methods";
import AllDoorsComponent from "../../components/AllDoorsComponent/AllDoorsComponent";

export default observer((props) => {
  const history = useHistory();
  const { lockerStore, doorsStore } = props;
  const { order } = lockerStore;
  const { openDoors } = doorsStore;

  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [modalEnd, setModalEnd] = useState(false);

  const openDoor = async (e, container) => {
    await doorsStore.checkOpenDoor();
    if (openDoors != null) {
      await doorsStore.openDoorCommandRetrieveOrder(container);
      await lockerStore.deallocateContainer(container.id);
    }
    setInitialSetupDone(true);
  };

  const handleOrderReverse = async () => {
    lockerStore.deallocateReverseOrder();
    await order.containers.forEach((door) => {
      openDoor(null, door);
    });
  }

  useEffect(() => {
    handleOrderReverse();

    return () => {
      doorsStore.cleaningIntervals();
    };
  }, []);

  console.log(openDoors);

  if (order && initialSetupDone && openDoors?.length == 0 && !modalEnd) {
    setModalEnd(true);
  }

  return (
    <React.Fragment>
      <InfoContinue
        show={modalEnd}
        timerEvent={timerEvent(history)}
        text={strings.get("reversa_feita_com_sucesso")}
        //onClickContinue={() => history.replace("/")}
        onClickContinue={() => {
          doorsStore.reset();
          history.replace(
            props.lockerStore.hasReversesOrder() > 1 ? "/reverse" : "/supply"
          )
        }}
      ></InfoContinue>

      <section className="global-structure section1">
        {/** TOP AREA*/}
        <TopComponent suply>
          <TitleHeaderComponent
          //back={<BackIconComponent onClick={history.goBack}/>}
          >
            {strings.get("reversa_do_pedido")} {order && order.number}
          </TitleHeaderComponent>
        </TopComponent>
        {/** TOP AREA*/}

        {/** CENTER AREA*/}
        <BlockComponent medium>
          <div className="text-left">
            <SpacerComponent>
              <div className="text-featured">{strings.get("portas")}</div>
            </SpacerComponent>

            {initialSetupDone != null && (
              <AllDoorsComponent
                containers={order ? order.containers : []}
                currentDoor={openDoors?.map((e) => e.toString())}
              />
            )}

            <AlertInfoComponent>
              Após abrir, retire os seus produtos e feche a porta. Para abrir
              uma nova porta, a porta anterior deve ser fechada.
            </AlertInfoComponent>
          </div>
        </BlockComponent>
        {/** CENTER AREA*/}

        {/** BOTTOM AREA*/}
        <BottomComponent></BottomComponent>
        {/** BOTTOM AREA*/}
      </section>
    </React.Fragment>
  );
});
