import { url_locker_mock } from "./utils/constants";

/*
    Config: Configurações para teste local, stating e  Prod.
    Por fazer conexão com backend e com o Locker (local) é necessário dois ambientes para cada configuração;

*/

const local = {
  token: "TOKEN_LOCAL",
  test: false,
  // PRD
  // url: "https://locker-petz.stoom.com.br",
  // DEV
  url: "https://locker-api-petz.dev.stoomlab.com.br/",
  // LOCAL
  // url: "http://localhost:8000",
  // PRD
  // urllocker: "http://localhost:8005",
  // DEV / LOCAL
  urllocker: "https://lass-laurenti-api.dev.stoomlab.com.br",
};
export default local;
