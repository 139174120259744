export default {
  toque_na_tela_para_comecar: "Toque na tela para começar",
  ola_tudo_bem: "Olá, tudo bem?",
  escaneie_o_qr_code:
    "Escaneie o qrcode do pedido ou insira o número do pedido do e-commerce",
  //posicione_o_qrcode_em_frente_ao_leitor: "Posicione o QR Code em frente ao leitor",
  posicione_o_qrcode_em_frente_ao_leitor: "",
  digite_o_numero_do_pedido_para_retirada:
    "Digite o número do pedido para retirada",
  numero_do_pedido: "Número do pedido",
  usar_pin_do_pedido: "Usar pedido E-commerce",
  insira_o_numero_do_pedido_do_ecommerce:
    "Insira o número do pedido do E-commerce",
  seja_bem_vindo: "Seja bem-vindo, ",
  portas: "Portas",
  toque_aqui: "Toque aqui",
  toque_no_pedido_para_comecar: "Toque no pedido para começar",
  para_abrir_a_porta: "para abrir a porta",
  apos_abrir_retire_os_seus_produtos_e_feche_a_porta:
    "Após abrir, retire os seus produtos e feche a porta.",
  para_abrir_uma_nova_porta_a_anterior_deve_ser_fechada:
    "Para abrir uma nova porta, a porta anterior deve ser fechada.",
  produto_retirado: "Produto retirado",
  numero_pedido: "Número do pedido",
  pedido_numero: "Pedido número:",
  feche_a: "Feche a",
  porta: "porta",
  para_abrir_a_proxima_porta: "para abrir a próxima porta",
  pedido_retirado_com_sucesso: "Pedido retirado com sucesso!",
  pedido_ja_retirado: "O pedido já foi retirado. Por favor, peça ajuda a um atendente em loja.",
  modulo_de_reversa: "Módulo de reversa",
  hoje_temos: "Hoje temos",
  reversas: "reversas",
  fazer_reversa: "Fazer reversa",
  reversa_do_pedido: "Reversa do pedido",
  feche_a_para_abrir_a_próxima_porta: "feche-a para abrir a próxima porta",
  reversa_feita_com_sucesso: "Reversa feita com sucesso!",
  reversa_ja_executada: "Reversa já executada",
  modulo_de_abastecimento: "Módulo de Abastecimento",
  abastecimento_do_pedido: "Abastecimento do pedido",
  abastecimento_feito_com_sucesso: "Abastecimento feito com sucesso",
  pedido_existente: "Pedido existente!",
  porta_disponivel: "Porta Disponível",
  porta_aberta: "Porta aberta",
  porta_abastecida: "Porta abastecida",
  cancelar_abastecimento: "Cancelar abastecimento",
  finalizar_abastecimento: "Finalizar abastecimento",
  finalizar_pedido: "Finalizar pedido",
  ola: "Olá",
  informe_pedido_para_abastecimento:
    "Informe o número do pedido para abastecimento.",
  digite_o_número_do_pedido: "Digite o número do pedido",
  continuar: "Continuar",
  sair: "Sair",
  selecione_uma_ou_mais_portas:
    "Selecione uma ou mais portas disponíveis para guardar os produtos.",
  veja_como_funciona: "Veja como funciona",
  veja_como_e_facil: "Veja como é fácil",
  reversa: "Reversa",
  abastecimento: "Abastecimento",
  ler_qrcode: "Ler QR Code",
  inserir_numero_do_pedido: "Inserir número do pedido",
  enviar: "Enviar",
  erro: "Erro",
  informe_o_numero_do_pedido_do_ecommerce:
    "Informe o número pedido do E-commerce",
  falha_ao_buscar_ou_numero_nao_encontrado:
    "Falha ao buscar número de pedido ou pedido não encontrado.",

  Informe_o_numero_do_pedido_do_ecommerce_utilizando_o_teclado:
    "Informe o número pedido do E-commerce utilizando o teclado",
  //Abra_o_QR_Code_do_seu_pedido_no_app_ou_no_email: "Abra o QR Code do seu pedido no app ou no e-mail",
  Vire_o_QR_Code_para_o_sensor_de_leitura_do_Armario_inteligente:
    "Vire o QR Code para o sensor de leitura do Armário inteligente",
  //Toque_nas_portas_que_estão_disponiveis_para_a_retirada_do_seu_pedido: "Toque nas portas que estão disponíveis para a retirada do seu pedido",
  Toque_nas_portas_que_estão_disponiveis_para_a_retirada_do_seu_pedido:
    "Toque nas portas que estão disponíveis para a retirada do seu pedido",

  Selecione_a_porta_para_retirar_os_seus_produtos:
    "Toque no ícone para abrir a porta.",
  Retire_os_seus_produtos_e_feche_a_porta:
    "Retire os seus produtos e feche a porta.",
  Selecione_a_próxima_porta_até_completar_as_retiradas:
    "Selecione a próxima porta até completar as retiradas",
};
